/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
// core components
import footerStyle from "assets/jss/components/footerStyle.jsx";
import { Link } from "react-router-dom";

function Footer({ ...props }) {
  const { classes } = props;
  return (
    <footer className={classes.footer}>
      <div className={classes.container}>
        <div className={classes.left}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <Link to="/about" className={classes.block}>
                About me
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link to="/experience" className={classes.block}>
                Experience
              </Link>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Link to="/courses" className={classes.block}>
                Courses
              </Link>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          <span>
            <a target="_blank" className={classes.a}>
              &copy; {new Date().getFullYear()} Simon Sundberg
            </a>
          </span>
        </p>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(footerStyle)(Footer);
