import React, { useState, useEffect } from "react";
import FirebaseContext from "./context";

export const useFirebase = () => {
  return React.useContext(FirebaseContext);
};

export const useAuth = () => {
  const firebase = useFirebase();
  const [auth, setAuth] = useState({ uid: "" });

  useEffect(() => {
    const cancel = firebase
      .auth()
      .onAuthStateChanged(auth => auth && setAuth(auth));
    return () => cancel();
  });

  return auth;
};
