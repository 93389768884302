import React from "react";
import classNames from "classnames";

import { makeStyles, useTheme, Grid, Typography } from "@material-ui/core";
import Camera from "@material-ui/icons/Camera";
import Favorite from "@material-ui/icons/Favorite";

import Header from "components/Header";
import Parallax from "components/Parallax";
import { GridContainer, GridItem } from "components/Grid";
import NavPills from "components/NavPills";
import Footer from "components/Footer";

import profilePageStyle from "assets/jss/views/profilePage";
import profile from "assets/img/women.png";
import parallaxImage from "assets/img/stars_header.jpg";
import sidebarImage from "assets/img/sidebar-4.jpg";

const useStyles = makeStyles(theme => ({
  ...profilePageStyle(theme),
  root: {
    display: "flex",
    minHeight: "100vh",
    overflow: "hidden",
    flexDirection: "column"
  },
  name: {
    color: "inherit",
    fontWeight: "600",
    fontSize: "24px",
    lineHeight: "30px",
    textDecoration: "none",
    marginTop: "-80px"
  },
  job: {
    color: "inherit",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    textDecoration: "none"
  },
  quote: {
    padding: "10px 20px",
    margin: "0 0 20px",
    fontSize: "17.5px",
    borderLeft: "5px solid #eee"
  },
  quoteText: {
    margin: "0 0 10px",
    fontStyle: "italic"
  },
  onTop: {
    zIndex: 101,
  },
  wrapper: {
    paddingRight: "15px",
    paddingLeft: "15px",
    paddingTop: "0px",
    paddingBottom: "0px",
    marginRight: "auto",
    marginLeft: "auto",
    paddingBottom: "20px",
    background: "#fff",
    maxWidth: "100%",
  }
}));
export default function AboutPage(props) {
  const classes = useStyles();
  const theme = useTheme();

  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid,  );

  const container = classNames({
    [classes.main]: true,
    [classes.mainRaised]: false,
    [classes.onTop]: true
  });
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  return (
    <div className={classes.root}>
      <Header
        brand="Simon Sundberg"
        color="transparent"
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        image={sidebarImage}
      />
      <Parallax mini filter image={parallaxImage} />
      <div className={container}>
        <div className={classes.wrapper}>
          <GridContainer justify="center" className={classes.wrapper}>
            <GridItem xs={12} sm={12} md={6}>
              <div className={classNames(classes.profile, classes.onTop)}>
                <div>
                  <img src={profile} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <Typography variant="h4" gutterBottom>
                    Simon Sundberg
                  </Typography>
                  <Typography variant="h6" className={classes.job} gutterBottom>
                    Software Engineer
                  </Typography>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classes.description}>
            <p>
              A humble software developer with exceptional taste. Writes code
              like Mozart write sonnets - giving it a warm, intimate feel with a
              solid groove structure.
            </p>
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
              <NavPills
                alignCenter
                color="primary"
                tabs={[
                  {
                    tabButton: "Description",
                    tabIcon: Camera,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.quote}
                        >
                          <p className={classes.quoteText}>
                            Lorem ipsum my name is Simon, consectetur adipiscing
                            elit. Nunc non egestas{" "}
                            <em>
                              <b>positive</b>
                            </em>
                            , ut commodo massa. Morbi semper pharetra et nibh{" "}
                            pharetra , et
                            <em>
                              <b> curious </b>
                            </em>
                            nibh eleifend. Interdum et tempor fames ac ante
                            ipsum primis in{" "}
                            <em>
                              <b>passionate </b>
                            </em>
                            . Curabitur viverra ornare lobortis. In bibendum
                            odio magna, ut venenatis nulla non. In nec congue
                            libero, et commodo leo. Aenean et tortor pharetra,{" "}
                            <em>
                              <b>learner</b>
                            </em>
                            .
                          </p>
                          {/** 
                          <img
                            alt="..."
                            src={studio1}
                            className={navImageClasses}
                          />
                          <img
                            alt="..."
                            src={studio2}
                            className={navImageClasses}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={4}>
                          <img
                            alt="..."
                            src={studio1}
                            className={navImageClasses}
                          />
                          <img
                            alt="..."
                            src={studio1}
                            className={navImageClasses}
                          />
                          */}
                        </GridItem>
                      </GridContainer>
                    )
                  },
                  {
                    tabButton: "Pseudo Deep Quote",
                    tabIcon: Favorite,
                    tabContent: (
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={4}
                          className={classes.quote}
                        >
                          <p className={classes.quoteText}>
                            "Everything is the way it is so it can become the
                            way it will be"
                          </p>
                        </GridItem>
                      </GridContainer>
                    )
                  }
                ]}
              />
            </GridItem>
          </GridContainer>
        </div>
        <Footer />
      </div>
    </div>
  );
}
