import { createMuiTheme } from "@material-ui/core/styles";
import { green, amber } from "@material-ui/core/colors";
const theme = createMuiTheme({
  palette: {
    primary: {
      // teal /lightblue
      light: "#f9683a",
      main: "#78a5a3",
      dark: "#870000",
      contrastText: "#fff"
    },
    secondary: {
      // orange/brown
      light: "#ffe390",
      main: "#FFA726",
      dark: "#ac8234",
      contrastText: "#000000"
    },

    info: {
      // light blue
      light: "#26c6da",
      main: "#00acc1",
      dark: "#00acc1"
    },
    rose: {
      // pink purple
      light: "#ec407a",
      main: "#e91e63",
      dark: "#d81b60"
    },
    warning: {
      // yellow/orange
      light: "#ffa726",
      main: "#ff9800",
      dark: "#fb8c00",
      contrastText: "#000000"
    },
    danger: {
      // light red / pink
      light: "#ef5350",
      main: "#f44336",
      dark: "#e53935"
    },
    background: {
      paper: "#555",
      default: "#555",
      placeholder: "#000000"
    }
  },
  typography: {
    h1: {
      fontSize: "3em",
      lineHeight: "1.15em"
    },

    h2: {
      fontSize: "2.4em"
    },

    h3: {
      fontSize: "1.825em",
      lineHeight: "1.4em",
      margin: "20px 0 10px"
    },

    h4: {
      fontSize: "1.3em",
      lineHeight: "1.4em"
    },

    h5: {
      fontSize: "1.25em",
      lineHeight: "1.4em",
      marginBottom: "15px"
    },
    h6: {
      fontSize: "1em",
      textTransform: "uppercase",
      fontWeight: 500
    }
  }
});

export default theme;
