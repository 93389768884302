import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider } from "@material-ui/core/styles";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";

import AboutPage from "views/AboutPage";
import ExperiencePage from "views/ExperiencePage";
import CoursesPage from "views/CoursesPage";
import ScrollToTop from "Utils/ScrollToTop";
import MainTheme from "assets/themes/MainTheme";

import "./App.css";

function App() {
  return (
    <MuiThemeProvider theme={MainTheme}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={PageShell(AboutPage)} />
            <Route path="/about" component={PageShell(AboutPage)} />
            <Route path="/courses" component={PageShell(CoursesPage)} />
            <Route path="/experience" component={PageShell(ExperiencePage)} />
          </Switch>
        </ScrollToTop>
      </Router>
    </MuiThemeProvider>
  );
}

const PageShell = (Page, previous) => {
  return props => (
    <div className="page">
      <ReactCSSTransitionGroup
        transitionAppear={true}
        transitionAppearTimeout={600}
        transitionEnterTimeout={600}
        transitionLeaveTimeout={600}
        transitionName={"SlideIn"} //props.match.path === "/about" ? "SlideIn" : "SlideOut"}
      >
        <Page {...props} />
      </ReactCSSTransitionGroup>
    </div>
  );
};

export default App;
