import React from "react";
import { Link, withRouter } from "react-router-dom";
import classNames from 'classnames';
import { makeStyles } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import headerLinksStyle from "assets/jss/components/headerLinksStyle";

const useStyles = makeStyles(theme => 
  headerLinksStyle(theme)
);
function HeaderLinks(props) {
  const classes = useStyles();
  const path = props.location.pathname;
  
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
          <Link to="/about" className={classNames({[classes.navLink]: true, [classes.navLinkActive]: path === "/about"})}>
            About me
          </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/experience" color="transparent" className={classNames({[classes.navLink]: true, [classes.navLinkActive]: path === "/experience"})}>
          Experience
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/courses" color="transparent" className={classNames({[classes.navLink]: true, [classes.navLinkActive]: path === "/courses"})}>
          Courses
        </Link>
      </ListItem>
    </List>
  );
}

export default withRouter(HeaderLinks);
