import React from "react";
import classNames from "classnames";
import { Timeline, TimelineItem } from "vertical-timeline-component-for-react";

import {
  Checkbox,
  Typography,
  makeStyles,
  useTheme,
  withStyles,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";
import { green, blue } from "@material-ui/core/colors";

import Header from "components/Header";
import Parallax from "components/Parallax";
import Footer from "components/Footer";

import pyro1 from "assets/img/pyro1.jpg";
import pyro3 from "assets/img/pyro3.jpg";
import walle1 from "assets/img/walle_stats.png";
import pyret1 from "assets/img/pyret_login.png";
import pyret2 from "assets/img/pyret1.png";

import dmc1 from "assets/img/dmc1.png";
import parallaxImage from "assets/img/stars_header2.jpg";
import profilePageStyle from "assets/jss/views/profilePage";

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  ...profilePageStyle,
  imgHalf: {
    maxWidth: "90%",
    height: "auto",
    padding: 10
  },
  root: {
    textAlign: "center",
    overflow: "hidden"
  },
  imgFull: {
    maxWidth: "90%",

    height: "auto"
  },
  checkList: {
    padding: "20px 0px"
  },
  checkBox: {
    color: green[400]
  },

  itemContainer: {
    color: "#455A64",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)",
    [theme.breakpoints.up("md")]: {
      width: "800px"
    }
  },
  title: {
    marginTop: "1.35em",
    marginBottom: 0,
    padding: 0,
    display: "0 auto",
    color: "#455A64",
    fontSize: "35px"
  },
  timeline: {
    marginTop: "1.35em"
  }
}));
export default function ExperiencePage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const imageClasses = classNames(classes.imgRaised, classes.imgHalf);

  const imageClasses2 = classNames(classes.imgRaised, classes.imgFull);
  return (
    <div className={classes.root}>
      <Header
        brand="Simon Sundberg"
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
      />
      <Parallax mini filter image={parallaxImage} />
      <Typography variant="h2" className={classes.title}>
        Notable experiences
      </Typography>

      <Timeline animate={true} lineColor={"#ddd"} className={classes.timeline}>
        <TimelineItem
          key="001"
          dateText="08/2016 – 06/2021"
          dateInnerStyle={{ background: theme.palette.secondary.main }}
          bodyContainerStyle={{
            background: "#ddd",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)",
            [theme.breakpoints.up["md"]]: {
              width: "800px"
            }
          }}
        >
          <Typography variant="h3">
            Master of Science in Software Engineering
          </Typography>
          <Typography variant="h4">Linköping University</Typography>
        </TimelineItem>
        <TimelineItem
          key="002"
          dateText="05/2019 – Present"
          dateInnerStyle={{ background: green[500] }}
          style={{ color: "#61b8ff" }}
          bodyContainerStyle={{
            background: "#ddd",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)",
            [theme.breakpoints.up["md"]]: {
              width: "800px"
            }
          }}
        >
          <Typography variant="h3">Airpelago - Drone mission control</Typography>
          <Typography variant="h4">Frontend developer</Typography>
          <FormGroup className={classes.checkList}>
            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="React"
            />
            <FormControlLabel
              control={<GreenCheckbox checked={true} value="checkedA" />}
              label="Typescript"
            />
            <FormControlLabel
              control={<GreenCheckbox checked={true} value="checkedA" />}
              label="Redux / Redux Saga"
            />
            <FormControlLabel
              control={<GreenCheckbox checked={true} value="checkedA" />}
              label="CSS"
            />
          </FormGroup>
          <img src={dmc1} alt="..." className={imageClasses2} />
        </TimelineItem>
        <TimelineItem
          key="003"
          dateText="05/2019 – 07/2019"
          dateInnerStyle={{ background: blue[400] }}
          bodyContainerStyle={{
            background: "#ddd",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)",
            [theme.breakpoints.up["md"]]: {
              width: "800px"
            }
          }}
        >
          <Typography variant="h3">Pyret life</Typography>
          <Typography variant="h4">Website dedicated to my dog </Typography>
          <FormGroup className={classes.checkList}>
            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="Javascript"
            />

            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="React"
            />
            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="Firebase database + hosting"
            />
            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="CSS"
            />
          </FormGroup>
          <img src={pyret1} alt="..." className={imageClasses2} />
          <img src={pyret2} alt="..." className={imageClasses2} />
        </TimelineItem>
        <TimelineItem
          key="004"
          dateText="01/2019 – 06/2019"
          dateInnerStyle={{ background: theme.palette.warning.light }}
          bodyContainerStyle={{
            background: "#ddd",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)",
            [theme.breakpoints.up["md"]]: {
              width: "800px"
            }
          }}
        >
          <Typography variant="h3">Bachelor Project</Typography>
          <Typography variant="h4">
            Visualize statistics and prediction
          </Typography>
          <FormGroup className={classes.checkList}>
            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="Python backend server / API"
            />
            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="React"
            />
            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="Javascript"
            />
            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="MongoDB"
            />
            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="CSS"
            />
          </FormGroup>

          <img src={walle1} alt="..." className={imageClasses2} />
        </TimelineItem>
        <TimelineItem
          key="005"
          dateText="08/2017 – 01/2018"
          dateInnerStyle={{ background: theme.palette.danger.main }}
          bodyContainerStyle={{
            background: "#ddd",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)",
            [theme.breakpoints.up["md"]]: {
              width: "800px"
            }
          }}
        >
          <Typography variant="h3">Pyro the Dog</Typography>
          <Typography variant="h4">Mobile application</Typography>
          <p>
            A game with a dog that can run and jump to gather coins and avoid
            enemies and get to the other side of the map before the water rises.
          </p>
          <FormGroup>
            <FormControlLabel
              control={<GreenCheckbox checked={true} />}
              label="Java"
            />
            <FormControlLabel
              control={<GreenCheckbox checked={true} value="checkedA" />}
              label="Game animation"
            />
          </FormGroup>
          <img src={pyro1} alt="..." className={imageClasses} />
          <img src={pyro3} alt="..." className={imageClasses} />
        </TimelineItem>
        <TimelineItem
          key="006"
          dateText="16/02/2012"
          dateInnerStyle={{ background: theme.palette.secondary.main }}
          bodyContainerStyle={{
            background: "#ddd",
            padding: "20px",
            borderRadius: "8px",
            boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)"
          }}
        >
          <Typography variant="h3">18th birthday</Typography>
          <Typography variant="h4">Midlife crisis</Typography>
          <p>
            Started taking online coding courses on sites such as Code Academy
          </p>
        </TimelineItem>
      </Timeline>
      <Footer />
    </div>
  );
}
