export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function sortByDate(arr) {
  arr.sort(function(a, b) {
    return new Date(b.date) - new Date(a.date);
  });
  return arr;
}
