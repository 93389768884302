/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { container, primaryColor } from "assets/jss";
import Background from "assets/img/stars_header2.jpg"

const footerStyle = theme => ({
  block: {
    color: "whitesmoke",
    padding: theme.spacing(1),
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    textDecoration: "none",
    position: "relative",
    display: "block"
  },
  left: {
    [theme.breakpoints.up("md")]: {
      float: "left!important"
    },
    display: "block"
  },
  right: {
    margin: "0",
    padding: theme.spacing(0.7),
    [theme.breakpoints.up("md")]: {
      float: "right!important"
    }
  },
  footer: {
    background: "#343434",
    padding: theme.spacing(1),
    textAlign: "center",
    display: "flex",
    flex: "none",
    zIndex: "2000",
    bottom: 0,
    width: "100%"
  },
  a: {
    color: "whitesmoke",
    textDecoration: "none",
    backgroundColor: "transparent"
  },
  footerWhiteFont: {
    "&,&:hover,&:focus": {
      color: "#FFFFFF"
    }
  },
  container,
  list: {
    marginBottom: "0",
    padding: "0",
    marginTop: "0"
  },
  inlineBlock: {
    display: "inline-block",
    padding: "0px 5px",
    width: "auto"
  },
  icon: {
    width: "18px",
    height: "18px",
    position: "relative",
  }
});
export default footerStyle;
