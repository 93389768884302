import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyA1qIKIwX0m6684pRwt3Lpogb4EoppDzzc",
  authDomain: "pimme-123f2.firebaseapp.com",
  databaseURL: "https://pimme-123f2.firebaseio.com",
  projectId: "pimme-123f2",
  storageBucket: "pimme-123f2.appspot.com",
  messagingSenderId: "783712763978",
  appId: "1:783712763978:web:428477aa2adb6fb5"
};

firebase.initializeApp(config);

class Firebase {
  constructor() {
    this._auth = firebase.auth();
    this._db = firebase.firestore();
    this.doSignOut();
  }

  auth = () => this._auth;

  db = () => this._db;

  storage = () => this._storage;

  changeStatus = (code, status) => {
    const doc = this.db()
      .collection("courses")
      .doc(code);
    doc.update({ status });
  };

  addCourse = async (name, code, date, category, points, grade, status) => {
    await this.db()
      .collection("courses")
      .doc(code)
      .set({ name, code, date, category, points, grade, status });
  };

  getCourses = async () => {
    const snapshot = await this.db()
      .collection("courses")
      .get();
    const courses = snapshot.docs.map(doc => ({
      ...doc.data()
    }));
    return courses;
  };

  signIn = (email, password) =>
    this.auth()
      .signInWithEmailAndPassword(email, password)
      .then(authUser => (this._user = authUser));

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth().createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth().signInWithEmailAndPassword(email, password);

  doSignOut = () => {
    this.auth().signOut();
  };

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);
}
export default Firebase;
