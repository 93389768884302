/*!

=========================================================
* Material Dashboard React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import BubbleChart from "@material-ui/icons/BubbleChart";
import LocationOn from "@material-ui/icons/LocationOn";
import Notifications from "@material-ui/icons/Notifications";
import Unarchive from "@material-ui/icons/Unarchive";
import Language from "@material-ui/icons/Language";
// core components/views for Admin layout
import AboutPage from "views/AboutPage";
import CoursesPage from "views/CoursesPage";
import ExperiencePage from "views/ExperiencePage";

// core components/views for RTL layout

const routes = [
  {
    path: "/about",
    name: "About me",
    icon: Person,
    component: AboutPage,
    layout: "/admin"
  },
  {
    path: "/experience",
    name: "Experience",
    icon: "content_paste",
    component: ExperiencePage,
    layout: "/experience"
  },
  {
    path: "/courses",
    name: "Courses",
    icon: Dashboard,
    component: CoursesPage,
    layout: "/courses"
  }
];

export default routes;

export const HOME = "/";
export const PHOTOS = "/photos";
export const VIDEOS = "/videos";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const ADMIN = "/admin";
export const ABOUT = "/about";
