import React, { useEffect, useState } from "react";
import {
  Favorite,
  Functions,
  HourglassFull,
  Keyboard
} from "@material-ui/icons";
import { CircularProgress, makeStyles, useTheme } from "@material-ui/core";

import { useFirebase } from "../Firebase";
import Header from "components/Header";
import Parallax from "components/Parallax";
import NavPills from "components/NavPills";
import { Card, CardHeader, CardBody } from "components/Card";
import { GridContainer, GridItem } from "components/Grid";
import Footer from "components/Footer";
import Table from "components/Table";

import parallaxImage from "assets/img/stars_header2.jpg";
import coursesPageStyle from "assets/jss/views/coursesPage";
import { sortByDate } from "Utils";
import tableStyle from "assets/jss/components/tableStyle";

const useStyles = makeStyles(theme => ({
  ...coursesPageStyle,
  root: {
    display: "flex",
    minHeight: "100vh",
    overflow: "hidden",
    flexDirection: "column"
  },
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },

  wrapper: {
    ...coursesPageStyle.container,
    paddingRight: "15px",
    paddingLeft: "15px",
    marginRight: "auto",
    marginLeft: "auto",
    overflow: "hidden",
    flex: "1 0 auto" /* 2 */,
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
      paddingLeft: "0px"
    }
  },
  table: {
    [theme.breakpoints.down("xs")]: {
      paddingRight: "0px",
      paddingLeft: "0px"
    }
  },
  progress: {
    margin: theme.spacing(2)
  },
  cardBody: {
    textAlign: "center"
  },
  gridTable: {
    margin: `-${theme.spacing(0)}px`
  }
}));
export default function CoursesPage(props) {
  const classes = useStyles();
  const theme = useTheme();
  const firebase = useFirebase();
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    const getCourses = async () => {
      await firebase.signIn("damnyouhaxxor@lololol.com", "haxxor1337");
      setCourses(await firebase.getCourses());
    };
    getCourses();
  }, []);

  const formatDate = courses => {
    courses.map(
      course => (course.date = new Date(course.date).toLocaleDateString())
    );
  };
  const programming = sortByDate(
    courses.filter(course => course.category === "Programming")
  );
  const math = sortByDate(courses.filter(course => course.category === "Math"));
  const science = sortByDate(
    courses.filter(course => course.category === "Science")
  );
  const other = sortByDate(
    courses.filter(course => course.category === "Other")
  );
  formatDate(programming);
  formatDate(math);
  formatDate(science);
  formatDate(other);

  return (
    <div className={classes.root}>
      <Header
        brand="Simon Sundberg"
        color="transparent"
        //rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
      />
      <Parallax mini filter image={parallaxImage} />
      <div className={classes.wrapper}>
        <GridContainer justify="center" className={classes.gridTable}>
          <GridItem xs={11} sm={12} md={12} className={classes.table}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "Programming",
                  tabIcon: Keyboard,
                  tabContent: (
                    <Card plain={window.innerWidth < 960}>
                      <CardHeader
                        color="primary"
                        plain={window.innerWidth < 960}
                      >
                        <h4 className={classes.cardTitleWhite}>Programming</h4>
                        <p className={classes.cardCategoryWhite}>
                          Courses that included programming
                        </p>
                      </CardHeader>
                      <CardBody plain={window.innerWidth < 960}  className={classes.cardBody}>
                        {programming.length ? (
                          <Table
                            tableHeaderColor="primary"
                            tableHead={["Name", "Code", "Date", "Points"]}
                            tableData={programming}
                          />
                        ) : (
                          <CircularProgress className={classes.progress} color="secondary" />
                        )}
                      </CardBody>
                    </Card>
                  )
                },
                {
                  tabButton: "Math",
                  tabIcon: Functions,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        <Card plain={window.innerWidth < 960}>
                          <CardHeader
                            color="primary"
                            plain={window.innerWidth < 960}
                          >
                            <h4 className={classes.cardTitleWhite}>Math</h4>
                            <p className={classes.cardCategoryWhite}>
                              Math courses
                            </p>
                          </CardHeader>
                          <CardBody plain={window.innerWidth < 960}>
                            <Table
                              tableHeaderColor="primary"
                              tableHead={["Name", "Code", "Date", "Points"]}
                              tableData={math}
                            />
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  tabButton: "Science",
                  tabIcon: Favorite,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={11} sm={12} md={12}>
                        <Card plain={window.innerWidth < 960}>
                          <CardHeader
                            color="primary"
                            plain={window.innerWidth < 960}
                          >
                            <h4 className={classes.cardTitleWhite}>Science</h4>
                            <p className={classes.cardCategoryWhite}>
                              Different science courses
                            </p>
                          </CardHeader>
                          <CardBody plain={window.innerWidth < 960}>
                            <Table
                              tableHeaderColor="primary"
                              tableHead={["Name", "Code", "Date", "Points"]}
                              tableData={science}
                            />
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  tabButton: "Other",
                  tabIcon: HourglassFull,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={11} sm={12} md={12}>
                        <Card plain={window.innerWidth < 960}>
                          <CardHeader
                            color="primary"
                            plain={window.innerWidth < 960}
                          >
                            <h4 className={classes.cardTitleWhite}>Other</h4>
                            <p className={classes.cardCategoryWhite}>
                              Other random courses
                            </p>
                          </CardHeader>
                          <CardBody plain={window.innerWidth < 960}>
                            <Table
                              tableHeaderColor="primary"
                              tableHead={["Name", "Code", "Date", "Points"]}
                              tableData={other}
                            />
                          </CardBody>
                        </Card>
                      </GridItem>
                    </GridContainer>
                  )
                }
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
      <Footer />
    </div>
  );
}
